import React from 'react'
import { useTranslation } from 'react-i18next';

// Components
import Newsletter from 'components/layout/Newsletter'

function Leadership() {
  const { t } = useTranslation();

  const directors = [
    {
      image: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/0799efe79a0e3019d6fd002eed444dd9edab1522cd0b38272a7abfc742ba6a58c2235cb20f1bc25226980f2a6ecd910ce0d9c5c73aac4f7f1c1bc2372e64ea32060c3e36de7a60c36e64ebb5a94ce9e4d8474f61bc7a31a3dbcf2d703c5c38ee40a3fab1a095f16f44e538510e3bde3d296b0ac4014e88778908/rez-file-1718682155845.jpg',
      name: "Delwyn Michael Sukamto",
      position: t('delwynPosition'),
    },
    {
      image: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/9605177b9eb9fa47c67e8974fa0eea368f74a5cdb285eafa89ed09d00bbb8f726b6baba06ba222352af5901c3047bdaa5415e9ab7e19d53b1bed69d81da285c4d35ac6c6084430cdadd4d7ae102d5d15f93adb3fb005f9170972e2be6b3079bcdabfeb703cd1b29aa5b41c3932e5af4a641d06d5907eb3976ba4/rez-file-1718682247270.jpg',
      name: "Rheinhard Brain",
      position: t('rheinhardPosition'),
    },
    {
      image: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/674237e62e2175345f418a6ad8deb6b3806c133ed4a430a86721b5b18d4eeebd0f78b38cfdf80eab74275fe221ecc5a3085e45b136e3c59028b53f6d5aea1c4048e8619c6a58c36e554de9f581951d3bc78e56ea96e6ff407f08af5ba8d22061d2762c30ed6de9c9b15d406434d16afa3f8810f8bba72c2dd228/rez-file-1718682220094.jpg',
      name: "Indra Hadiyanto",
      position: t('indraPosition'),
    },
    {
      image: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/2aa0951ea536013b1d57a82c6f487016eaaf64ad71493d79cffe2ed4d543ed78d025568d0d303baa7df1b709cca85e1320f32e78d67b2abea15eaa82f5e1fb8d358714707c38b874e208257c8d0ebc25d3f7ce131a298dc907350ea5adf201a5b5900242bc7ca3a0539ec5d2814ffd1da4baf2ffeab60050b5ce/rez-file-1718682124802.jpg',
      name: "Bobby Luis",
      position: t('bobbyPosition'),
    }
  ]

  const commisioners = [
    // {
    //   image: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/467af3cec60697ac29e096a7e6c18a5fcc67d393fbc8d5580e1da023114b3661dac27d4f5ee467241e3bdfeaf4fa9fc7c1d28b77c0ea88f051cc519221bc12c7bc2772237a30243419dc157c9ebc47da834dca96762edc125692f9df12145376317965a38efea089c7c87bec6f4f77d4642c5b3b33f7d6b4eac2/rez-file-1718682055455.jpg',
    //   name: "Zulkahfi Hidayat",
    //   position: t('zulkahfiPosition'),
    // },
    {
      image: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/1dd8b423043ab5d917a144cf387ba0867379d99ae070be4303aab2823898046c6a6664e78f77e8ed6a27f78d4fce8f4bad8907980cb64a351a66d89a03c4a3e7cbc831c23e64ce724fc203b6d505736e1bde99467f9d2992079336f24223bc8a83ad1f63cecca57a964cc2ac83ce1ffc27de3ca2768d2ba75d47/rez-file-1718681963255.jpg',
      name: "Poerwanto Pranowo",
      position: t('poerwantoPosition'),
    },
  ]

  return (
    <div>
      <section>
        <div className='relative'>
          <img src="https://storage.googleapis.com/nizerteklintasbumi/banner/SF_FF_KohaiInfiniti_OnePieceExhibition-9362.jpeg" alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-center' />
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
            <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>{t('leadershipBannerText')}</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('leadershipTitle1')}
          </p>
          <div className='grid grid-cols-2 sm:grid-cols-[repeat(auto-fit,_23%)] lg:grid-cols-[repeat(auto-fit,_23%)] m-auto gap-6 py-10 flex justify-center'>
            {commisioners.map((item, i) => (
              <div key={i} className='space-y-2 app-animation-scale-start'>
                <img src={item.image} alt={item.name} className='h-48 lg:h-72 w-full object-top rounded-t-2xl object-cover' />
                <p className='text-sm sm:text-base font-bold uppercase'>{item.name}</p>
                <p className='text-xs sm:text-sm text-app-blue-3 uppercase'>{item.position}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('leadershipTitle2')}
          </p>
          <div className='grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-4 gap-6 py-10 flex justify-center'>
            {directors.map((item, i) => (
              <div key={i} className='space-y-2 app-animation-scale-start'>
                <img src={item.image} alt={item.name} className='h-48 lg:h-72 w-full object-top rounded-t-2xl object-cover' />
                <p className='text-sm sm:text-base font-bold uppercase'>{item.name}</p>
                <p className='text-xs sm:text-sm text-app-blue-3 uppercase'>{item.position}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* <Newsletter /> */}
    </div>
  )
}

export default Leadership